exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-okr-basics-js": () => import("./../../../src/pages/okr-basics.js" /* webpackChunkName: "component---src-pages-okr-basics-js" */),
  "component---src-pages-okr-course-js": () => import("./../../../src/pages/okr-course.js" /* webpackChunkName: "component---src-pages-okr-course-js" */),
  "component---src-pages-okr-examples-js": () => import("./../../../src/pages/okr-examples.js" /* webpackChunkName: "component---src-pages-okr-examples-js" */),
  "component---src-pages-okr-templates-js": () => import("./../../../src/pages/okr-templates.js" /* webpackChunkName: "component---src-pages-okr-templates-js" */),
  "component---src-pages-signup-complete-js": () => import("./../../../src/pages/signup-complete.js" /* webpackChunkName: "component---src-pages-signup-complete-js" */),
  "component---src-pages-signup-confirmed-js": () => import("./../../../src/pages/signup-confirmed.js" /* webpackChunkName: "component---src-pages-signup-confirmed-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-okr-example-js": () => import("./../../../src/templates/okrExample.js" /* webpackChunkName: "component---src-templates-okr-example-js" */),
  "component---src-templates-okr-post-js": () => import("./../../../src/templates/okrPost.js" /* webpackChunkName: "component---src-templates-okr-post-js" */),
  "component---src-templates-okr-tag-js": () => import("./../../../src/templates/okrTag.js" /* webpackChunkName: "component---src-templates-okr-tag-js" */),
  "component---src-templates-okr-template-js": () => import("./../../../src/templates/okrTemplate.js" /* webpackChunkName: "component---src-templates-okr-template-js" */)
}

